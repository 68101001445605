import { BoxProps } from "@chakra-ui/react";
import React from "react";

import { Banner } from "~compositions/banner/Banner";
import { NextImage } from "~elements/NextImage";
import { pagesPath } from "~generated/$path";

export const BannerTokyo: React.FC<BoxProps> = (props) => (
  <Banner href={pagesPath.tokyo.$url()} {...props}>
    <NextImage height={280} src="/tokyo/banner.jpg" width={280} />
  </Banner>
);
