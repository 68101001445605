import { AspectRatio, Box, Flex, SimpleGrid, Img, Heading } from "@chakra-ui/react";
import { NextSeo } from "next-seo";
import React from "react";
import Slider, { Settings } from "react-slick";

import theme from "~/theme";
import { BannerResearch } from "~compositions/banner/BannerResearch";
import { BannerTokyo } from "~compositions/banner/BannerTokyo";
// import { BlogInformationWidget } from "~compositions/blog/BlogInformationWidget";
// import { BlogStationWidget } from "~compositions/blog/BlogStationWidget";

const sliderSetting: Settings = {
  adaptiveHeight: false,
  arrows: false,
  autoplay: true,
  autoplaySpeed: 0,
  centerMode: true,
  cssEase: "linear",
  dots: false,
  infinite: true,
  pauseOnFocus: false,
  pauseOnHover: false,
  responsive: [
    {
      breakpoint: theme.breakpoints.sm,
      settings: {
        slidesToShow: 1,
      },
    },
    {
      breakpoint: theme.breakpoints.md,
      settings: {
        slidesToShow: 2,
      },
    },
    {
      breakpoint: theme.breakpoints.lg,
      settings: {
        slidesToShow: 3,
      },
    },
    {
      breakpoint: theme.breakpoints.xl,
      settings: {
        slidesToShow: 5,
      },
    },
  ],
  slidesToShow: 5,
  speed: 10000,
};

export default function Home() {
  return (
    <Box pb={10}>
      <NextSeo
        description="東京に通勤・通学する人にお役に立つ 様々なサービスを提供します。あなたのお部屋探しを徹底サポートします"
        openGraph={{ type: "website" }}
      />

      <Flex alignItems="center" justify="center" position="relative" style={{ height: "400px" }}>
        <Box bottom="0" left="0" pos="absolute" right="0" top="0">
          <Slider {...sliderSetting}>
            {Array(6)
              .fill(null)
              .map((_, i) => (
                <Box h="400px" key={i}>
                  <AspectRatio ratio={1 / 2}>
                    <img src={`/top/room${i + 1}.jpg`} style={{ height: "400px" }} />
                  </AspectRatio>
                </Box>
              ))}
          </Slider>
        </Box>

        <Heading as="h1" zIndex="docked">
          <Img alt="お部屋探しをお助け「ルーミット」 | ROOMiT" src="/top/first_view_text.svg" />
        </Heading>
      </Flex>

      <Box>
        <Box fontSize="3xl" mt={24} textAlign="center">
          初めての方
        </Box>
        <BannerTokyo mt={6} mx="auto" />

        <Box fontSize={{ base: "xl", md: "3xl" }} mt={24} textAlign="center">
          お部屋探しのお役立ちサービス
        </Box>

        <SimpleGrid columns={{ md: 1 }} gap={10} maxW="container.md" mt={8} mx="auto">
          <Box>
            <BannerResearch mx="auto" />
          </Box>
          {/* <Box>
            <BannerViewing mx="auto" />
          </Box> */}
        </SimpleGrid>

        {/* <Container>
          <BlogStationWidget horizontal limit={10} mt={24} overflow="hidden" showMarketPrice showTitle />
          <BlogInformationWidget horizontal mt={24} />
        </Container> */}
      </Box>
    </Box>
  );
}
